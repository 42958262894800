<template>
  <div class="pa-4 pa-sm-6">
    <v-card class="bs-shadow-none mb-4">
      <div class="pa-4 pa-sm-6">
        <h3 class="font-weight-bold">Tutorial</h3>
      </div>
    </v-card>
    <div class="bs-row">
      <div class="bs-col-sm-6 mx-auto">
        <v-card class="bs-shadow-none">
          <div class="pa-4 pa-sm-6">
            <div v-if="step == 1">
              <h3 class="font-weight-bold mb-4">How to add token</h3>
              <ul>
                <li>Click the "Add Token" button</li>
                <li>Fill in the needed information then click submit</li>
              </ul>
              <div class="pa-4 pa-sm-6 pa-md-8 text-center">
                <v-img
                  :src="require('@/assets/addpoints.svg')"
                  width="300"
                  class="d-inline-block"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="grey"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
              <div class="d-flex justify-end">
                <v-btn color="primary" @click="step = 2" depressed>Next</v-btn>
              </div>
            </div>
            <div v-if="step == 2">
              <h3 class="font-weight-bold mb-4">How to play</h3>
              <ul>
                <li>Select game and click the "Play Now" button</li>
              </ul>
              <div class="pa-4 pa-sm-6 pa-md-8 text-center">
                <v-img
                  :src="require('@/assets/howtoplay/step1.svg')"
                  width="300"
                  class="d-inline-block"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="grey"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
              <div class="d-flex justify-end bs-gap-2">
                <v-btn @click="step = 1" text>Back</v-btn>
                <v-btn color="primary" @click="step = 3" depressed>Next</v-btn>
              </div>
            </div>
            <div v-if="step == 3">
              <h3 class="font-weight-bold mb-4">How to play</h3>
              <ul>
                <li>Select a number combination</li>
              </ul>
              <div class="pa-4 pa-sm-6 pa-md-8 text-center">
                <v-img
                  :src="require('@/assets/howtoplay/step2.svg')"
                  width="300"
                  class="d-inline-block"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="grey"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
              <div class="d-flex justify-end bs-gap-2">
                <v-btn @click="step = 2" text>Back</v-btn>
                <v-btn color="primary" @click="step = 4" depressed>Next</v-btn>
              </div>
            </div>
            <div v-if="step == 4">
              <h3 class="font-weight-bold mb-4">How to play</h3>
              <ul>
                <li>Select a token amount and click the "Play Now" button</li>
              </ul>
              <div class="pa-4 pa-sm-6 pa-md-8 text-center">
                <v-img
                  :src="require('@/assets/howtoplay/step3.svg')"
                  width="300"
                  class="d-inline-block"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="grey"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
              <div class="d-flex justify-end bs-gap-2">
                <v-btn @click="step = 3" text>Back</v-btn>
                <v-btn color="primary" @click="step = 5" depressed>Next</v-btn>
              </div>
            </div>
            <div v-if="step == 5">
              <h3 class="font-weight-bold mb-4">How to withdraw</h3>
              <ul>
                <li>Click the withdraw in the sidebar</li>
                <li>Fill in the needed information then click submit</li>
              </ul>
              <div class="pa-4 pa-sm-6 pa-md-8 text-center">
                <v-img
                  :src="require('@/assets/withdraw.svg')"
                  width="300"
                  class="d-inline-block"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="grey"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
              <div class="d-flex justify-end bs-gap-2">
                <v-btn @click="step = 1" text>Repeat</v-btn>
                <v-btn color="primary" @click="play()" depressed>Play</v-btn>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    step: 1,
  }),
  methods: {
    play() {
      this.$router.push({ name: "EndingGames" });
    },
  },
};
</script>
